import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'


const firebaseConfig = {
  apiKey: "AIzaSyBy02_7aQhY1jYVkfwQieBYe1MzbP1-C68",
  authDomain: "graphopti.firebaseapp.com",
  projectId: "graphopti",
  storageBucket: "graphopti.appspot.com",
  messagingSenderId: "871722867900",
  appId: "1:871722867900:web:4644287e3dd863a5f4c4b9",
  measurementId: "G-68L477MEGR"
};


const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export { app, analytics }
