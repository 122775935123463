import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import '../styles/header.css' // Make sure the path is correct
import logo from '../assets/colorful_logo_45.png' // Adjust path as needed
import languageIcon from '../assets/language.svg' // Adjust path as needed

const Header = ({ language, setLanguage }) => {
  const [productsDropdownOpen, setProductsDropdownOpen] = useState(false)
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  function toggleLanguage () {
    setLanguage(language.startsWith('zh') ? 'en' : 'zh')
  }

  return (
    <header className={`header ${isScrolled ? 'scroll' : ''}`}>
      <div className='flex items-center'>
        <a href='/' className='logo'>
          <img
            src={logo}
            alt='GraphOpti Logo'
            style={{ width: '30px', height: '30px' }}
          />
          <span id='company-name'>
            {language === 'en' ? 'GraphOpti' : '图优运算'}
          </span>
        </a>
        <nav className='nav ml-10'>
          <div className='dropdown relative'>
            <button
              onClick={() => setProductsDropdownOpen(!productsDropdownOpen)}
              className='button'
            >
              {language === 'en' ? 'Products' : '产品'}
            </button>
            {productsDropdownOpen && (
              <div className='dropdown-menu'>
                <Link to='/hecate'>
                  {language === 'en'
                    ? 'Hecate - Swap Face Online'
                    : 'Hecate - 在线换脸'}
                </Link>{' '}
              </div>
            )}
          </div>
          <div className='dropdown relative'>
            <button
              onClick={() => setCompanyDropdownOpen(!companyDropdownOpen)}
              className='button'
            >
              {language === 'en' ? 'About' : '关于'}
            </button>
            {companyDropdownOpen && (
              <div className='dropdown-menu'>
                <a href='mailto:contact@graphopti.com'>
                  {language === 'en' ? 'Contact us' : '联系我们'}
                </a>
              </div>
            )}
          </div>
        </nav>
      </div>
      <button onClick={toggleLanguage} className='language-button'>
        <img src={languageIcon} alt='Toggle Language' />
      </button>
    </header>
  )
}

export default Header
