import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Header from './components/header'
import Footer from './components/footer'
import Main from './components/main'
import Hecate from './components/hecate'
import NotFound from './components/NotFound'

function App () {
  const detectBrowserLanguage = () => {
    const browserLanguage = navigator.language || navigator.userLanguage
    return browserLanguage.startsWith('zh') ? 'zh' : 'en'
  }

  const [language, setLanguage] = useState(
    localStorage.getItem('selectedLanguage') || detectBrowserLanguage()
  )

  useEffect(() => {
    localStorage.setItem('selectedLanguage', language)
  }, [language])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-2QCK257Z62'
    script.async = true
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []
    function gtag () {
      // eslint-disable-next-line no-undef
      dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'G-2QCK257Z62')
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/6709e09d2480f5b4f58c62bd/${
          language === 'zh' ? '1i9vd5933' : '1i9v9aqmk'
        }';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();
    `
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [language])

  const getTitle = () => {
    return language === 'zh' ? '图优运算' : 'GraphOpti'
  }

  const getDescription = () => {
    return language === 'zh'
      ? '图优运算 - 推动世界进入空间计算纪元'
      : 'GraphOpti - An organization that drives the world into the era of spatial computing.'
  }

  return (
    <BrowserRouter>
      <div className='App'>
        <Helmet>
          <title>{getTitle()}</title>
          <html lang={language} />
          <meta name='description' content={getDescription()} />
        </Helmet>
        <Header language={language} setLanguage={setLanguage} />
        <Routes>
          <Route path='/' element={<Main language={language} />} />
          <Route path='/hecate' element={<Hecate language={language} />} />
          <Route path='*' element={<NotFound language={language} />} />
        </Routes>
        <Footer language={language} />
      </div>
    </BrowserRouter>
  )
}

export default App
