import React from 'react'
import '../styles/footer.css'

const Footer = ({ language }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <footer className='footer bg-black text-white pt-8'>
      <div className='max-w-6xl'>
        <div className='footer-section'>
          <p className='Copyright'>
            {language === 'en' ? 'GraphOpti © 2024' : '图优运算 © 2024'}
          </p>
          <a
            href='https://beian.miit.gov.cn/'
            target='_blank'
            rel='noopener noreferrer'
            className={language === 'en' ? 'icp-link-black' : 'icp-link'}
          >
            {'沪ICP备2024064397号'}
          </a>
        </div>
        <div className='center-section w-full md:w-auto flex justify-center order-3 md:order-2 mb-4 md:mb-0'>
          <button
            onClick={scrollToTop}
            className='back-to-top hover:text-gray-400 cursor-pointer p-2'
          >
            {language === 'en' ? 'Back to top ↑' : '回到顶部 ↑'}
          </button>
        </div>
        <div className='right-section w-full md:w-auto order-2 md:order-3 flex flex-col items-end'>
          <a
            href='https://github.com/GraphOpti'
            className='hover:text-gray-400'
            target='_blank'
            rel='noopener noreferrer'
          >
            GitHub
          </a>
          {/* Additional social links can be added here */}
        </div>
      </div>
    </footer>
  )
}

export default Footer
