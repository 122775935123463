import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebase' // Ensure this path is correct
import '../styles/not-found.css' // Ensure correct path
import backgroundImage from '../assets/background.webp' // Adjust path as needed

function NotFound ({ language }) {
  useEffect(() => {
    // Log the event when the component mounts
    logEvent(analytics, 'page_not_found', {
      page_title: '404 - Not Found',
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_referrer: document.referrer,
      browser_language: navigator.language || navigator.userLanguage,
      browser_info: navigator.userAgent,
      trigger_time: new Date().toISOString(),
      user_locale: navigator.language || navigator.userLanguage,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
  }, [])

  return (
    <main className='not-found'>
      <div className='background'>
        <img
          src={backgroundImage}
          alt='Background'
          className='background-image'
        />
      </div>
      <div className='content'>
        <h1>
          {language === 'en' ? '404 - Page Not Found' : '404 - 页面未找到'}
        </h1>
        <h2>
          {language === 'en'
            ? 'Sorry, the page you are looking for does not exist.'
            : '抱歉，您请求的页面不存在。'}
        </h2>
        <Link to='/'>
          <button>{language === 'en' ? 'Back to Home' : '返回主页'}</button>
        </Link>
      </div>
    </main>
  )
}

export default NotFound
